import React, {cloneElement, ReactElement, useState} from 'react'
import {Container, Grid} from "@material-ui/core";
import SEO from "../../components/seo/seo";
import Text from "../../components/data-display/text";
import makeStyles from "@material-ui/core/styles/makeStyles";
import InlineLink from "../../components/data-display/inline-link";

const useStyles = makeStyles(() => ({
    subHeading: {
        marginBottom: "12px"
    },
}));

const Paragraph = ({children, number}: {children: React.ReactNode, number?: string}) => {
    return (
        <div style={{ display: "flex", marginBottom: "12px" }}>
            <Text variant={"body1"} regular style={{ width: "52px", marginLeft: "12px" }}>
                {number}
            </Text>
            <Text variant={"body1"} regular style={{ flex: "1" }}>
                {children}
            </Text>
        </div>
    )
}

const ParagraphWrapper = ({children, number}: {children: React.ReactNode, number: string}) => {


    let processElements = (reactElements: Array<Exclude<React.ReactNode, boolean | null | undefined>>) => {
        reactElements.forEach((el, index) => {
            let element = el as ReactElement;
            reactElements[index] = cloneElement(element, {
                number: number + "." + (index+1).toString(),
            });
        });
        return reactElements;
    };

    let elements = React.Children.toArray(children);
    elements = processElements(elements);

    return (
        <div>
            {elements}
        </div>
    )
}

const TermsOfService = () => {

    const classes = useStyles();

    return (
        <>
            <SEO description={"Sydetasker will solely share and disclose your data in accordance with your instructions, including any applicable terms in the Terms of Service, and in compliance with applicable law and legal process."} title={"Terms of Service"}/>
            <div style={{ width: "100%", background: "rgb(235,235,235)", height: "30vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                <div style={{ height: "64px" }}/>
                <Text variant={"h1"} black style={{ textAlign: "center" }}>{"Terms of Service"}</Text>
            </div>
            <Container fixed maxWidth={"md"}>
                <div style={{ padding: "10vh 16px" }}>
                    <Text variant={"body1"} medium className={classes.subHeading}>
                        Effective: 23rd March 2021
                    </Text>
                    <div style={{ height: "24px" }}/>
                    <Text variant={"body1"} regular>
                        The Sydetasker Terms of Service (updated March 2021) outline Sydetasker’s and your obligations and responsibilities on the Sydetasker Website, App and Services.
                        <br/>
                        <br/>
                        Please read these Terms of Service carefully. By creating an account on our platforms, you accept that
                        <ol>
                            <li>You have read, understood, and agreed to be bound by these terms of use, including additional policies referenced herein and/or referenced by links</li>
                            <li>You are of legal age to form a binding contract with Sydetasker or you are the age of majority in your state of residence and you have given us your consent to allow any of your minor dependents to use this site</li>
                            <li>You have the authority to enter into the terms of use personally or on behalf of the company you have named as the user, and to bind that company to the terms of use</li>
                        </ol>
                        <br/>
                        <br/>
                        The term <b>"you"</b> refers to all users of the site, including without limitation, users who are browsers, vendors, customers, merchants, and/or contributors of content, individuals or legal entities. The term <b>“Company”</b> refers to Sydetasker. The term <b>“Tasker”</b> refers to the individual performing the services to fulfil the duties listed in a task. The term <b>“Poster”</b> refers to the individual publishing the task to be completed.
                        <br/>
                        <br/>
                        IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS OF USE, YOU MAY NOT ACCESS OR USE THIS WEBSITE OR SERVICES.
                    </Text>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       1. SCOPE OF Sydetasker’S WEBSITE, APP, AND SERVICES
                    </Text>
                    <ParagraphWrapper number={"1"}>
                        <Paragraph>
                            Sydetasker provides the Sydetasker Website, App and Services to enable Posters to publish Tasks
                        </Paragraph>
                        <Paragraph>
                            Taskers may make an offer in response to a posted job. Some details of the posted Task details will be made publicly available to Tasker & users accessing our services.
                        </Paragraph>
                        <Paragraph>
                            A Poster may revoke or modify a poster Task at any time before they accepts an offer. Sydetasker reserves the right to cancel all offers on a posted Task made prior to the modification.
                        </Paragraph>
                        <Paragraph>
                            If a Poster accepts an offer on Sydetasker's Website, App or Services, the posted Task becomes unavailable to other Taskers. Sydetasker in its sole and absolute discretion may make a posted Task available again to other Taskers if it deems necessary.
                        </Paragraph>
                        <Paragraph>
                            Upon acceptance of the offer, the Poster and Tasker have created an Executory Job Contract that outlines the obligations each party owes to the other and specifies the Services, Agreed Price, start date, end date, and estimated duration of the Posted Task. The Poster and Tasker are encouraged to use Sydetasker’s private messaging system to amend or vary the Executory Job Contract (including the Agreed Price) or to otherwise communicate.
                        </Paragraph>
                        <Paragraph>
                            Sydetasker may in its sole discretion require additional paperwork to be completed by Users and returned to Sydetasker before Services may be rendered through the Sydetasker platform. For Services exceeding $600, Users must complete and return to Sydetasker a Form 1099 from the Internal Revenue Service, and any other other paperwork Sydetasker deems necessary.
                        </Paragraph>
                        <Paragraph>
                            Both parties, the Poster and Tasker acknowledge and agree that the duties under the Executory Job Contract are not fully performed, but a promise to modify the contract is binding if the modification is fair and equitable in view of circumstances not anticipated by the parties when the contract was made.
                        </Paragraph>
                        <Paragraph>
                            Upon creation of an Executory Job Contract, the Poster must pay the Agreed Price using Sydetasker's Website, App or Services.
                        </Paragraph>
                        <Paragraph>
                            Upon creation of the Executory Job Contract, Sydetasker has rendered Sydetasker Services and the Service Fee is due and payable.
                        </Paragraph>
                        <Paragraph>
                            If either party wishes to amend or modify the terms of the Executory Job Contract, including terms regarding time, date, duration, services and/or Agreed Price, the parties may agree to do so and the original promise is still binding if the modification is fair and equitable in view of circumstances not anticipated by the parties when the contract was made.
                        </Paragraph>
                        <Paragraph>
                            In the case that the parties agree to modify the contract terms, both parties, the Tasker and Poster, must record that amendment or modification on the Sydetasker Website, App or Services. Failure to update the Executory Job Contract with amendments or modification may result in cancellation or suspension of the User’s account, including removal of all Tasks and Offers by those Users.
                        </Paragraph>
                        <Paragraph>
                            If the Poster and the Tasker fail to reach an agreement regarding the modification of terms, the Original Executory Job Contract stands and both parties must fulfill the duties outlined in the Original Executory Job Contract. Sydetasker does not have any obligations to either party.
                        </Paragraph>
                        <Paragraph>
                            Once the services in the Executory Job Contract are complete, the Tasker must provide notice of completion on the Sydetasker Platform.
                        </Paragraph>
                        <Paragraph>
                            Once the services in the Executory Job Contract are complete, the Poster must provide notice of completion on the Sydetasker Platform.
                        </Paragraph>
                        <Paragraph>
                            Once the Posted Task has been completed and the Poster confirms the services are completed, or if Sydetasker is satisfied the services have been completed, the Agreed Price will be released by Sydetasker to the Tasker. The Tasker will receive the Payment in their nominated bank account after at least 24 hours.
                        </Paragraph>
                        <Paragraph>
                            After the Job Contract is completed, the parties are encouraged to review and provide feedback of the Job on the Sydetasker Platform.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       2. Sydetasker's ROLE AND OBLIGATIONS
                    </Text>
                    <ParagraphWrapper number={"2"}>
                        <Paragraph>
                            Sydetasker provides the Sydetasker Platform only, enabling Users to publish tasks and make offers on tasks.
                        </Paragraph>
                        <Paragraph>
                            Sydetasker only permits individuals over 18 years of age to become Users. Sydetasker’s website, app, and services are not targeted towards or intended for use by anyone under the age of 18. You may not use the services or sign up for an account if you are not at least 18 years of age. If we later discover or suspect that a person is at least 18 years of age, we reserve the right to take steps to cancel access to the Service, including cancellation of such person’s account.
                        </Paragraph>
                        <Paragraph>
                            Users must be natural persons, but can specify within their account description that they represent a Business entity. Employees working for a business that is registered as a User acknowledge that they are agents of the Business registered as a User and that the Business is fully liable for acts performed within the scope of their duties and obligations as an employee. Businesses registered as Users fully acknowledge that their employees are their agents and are completely liable for any acts performed by their employees that are within the scope of their duties and obligations as an employee.
                        </Paragraph>
                        <Paragraph>
                            In its absolute discretion, Sydetasker may refuse to allow any person to register or create an account with Sydetasker or cancel or suspend or modify any existing account including if Sydetasker reasonably forms the view that a User's conduct (including a breach of this Agreement) is detrimental to the operation of the Sydetasker Platform.
                        </Paragraph>
                        <Paragraph>
                            In its absolute discretion, Sydetasker may modify or make changes to the Website, App, and Services at any time for any reason without notice, including but not limited to content changes. The Company has no obligation to make updates to information. You agree that it is your responsibility to monitor changes to Sydetasker’s content.
                        </Paragraph>
                        <Paragraph>
                            Registering and creating an account with Sydetasker is free. There is no charge for a Poster to post or for other Sydetasker Users to view content on the Sydetasker Platform, including posted jobs.
                        </Paragraph>
                        <Paragraph>
                            Sydetasker accepts no liability for any aspect of the Poster and Tasker interaction, including but not limited to the description, performance or delivery of services.
                        </Paragraph>
                        <Paragraph>
                            Sydetasker has no responsibility and makes no warranty as to the truth or accuracy of any aspect of any information provided by Users, including, but not limited to, the ability of Taskers to perform jobs or supply items, or the honesty or accuracy of any information provided by Posters or the Posters' ability to pay for the services requested.
                        </Paragraph>
                        <Paragraph>
                            The Sydetasker SERVICE IS PROVIDED ON AN "AS IS" BASIS, AND WITHOUT ANY WARRANTY OR CONDITION, EXPRESS OR IMPLIED. To the extent permitted by law, we and our affiliates specifically disclaim any implied warranties of title, merchantability, fitness for a particular purpose and non-infringement.
                        </Paragraph>
                        <Paragraph>
                            Sydetasker has no obligation to any User to assist or involve itself in any dispute between Users, although may do so to improve User experience.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       3. USER OBLIGATIONS
                    </Text>
                    <ParagraphWrapper number={"3"}>
                        <Paragraph>
                            You will at all times:
                            <ol style={{ listStyleType: "lower-alpha" }}>
                                <li>Comply with this Agreement (including all Policies) and all applicable laws and regulations</li>
                                <li>Only post accurate information on the Sydetasker website, app and services</li>
                                <li>Ensure that You are aware of any laws that apply to You as a Poster or Tasker, or in relation to using the Sydetasker website, app and services</li>
                            </ol>
                        </Paragraph>
                        <Paragraph>
                            You may not use the services or register as a User if you are not at least 18 years of age. Sydetasker’s website, app, and services are not targeted towards or intended for use by anyone under the age of 18. Sydetasker only permits individuals over the age of 18 to become Users. If we later discover or suspect that a person is at least 18 years of age, we reserve the right to take steps to cancel access to the service, including cancellation of such person’s User’s account.
                        </Paragraph>
                        <Paragraph>
                            You also agree that you will NOT:

                            <ol style={{ listStyleType: "lower-alpha" }}>
                                <li>reproduce, duplicate, copy, distribute, republish, download, display, reverse engineer, sell, resell or exploit any portion of or content on the Website, App, or Service, or any contact on the website through which the service is provided, without express permission from Sydetasker;</li>
                                <li>use our website, app, or services for any unlawful or unauthorized purpose, or to solicit others to perform or participate in unlawful acts, or violate our intellectual property rights or the intellectual property rights of others;</li>
                                <li>submit false, inaccurate, misleading, or deceptive information; or submit or post obscene material or images that, in Sydetasker’s sole discretion, is any way inappropriate or unlawful.</li>
                                <li>collect or track the personal information of others, or use the website or services to spam, phish, pharm, pretext, spider, crawl, or scrape;</li>
                                <li>upload or transmit any worms or viruses or any code of a destructive nature or any type of malicious code that will or may be used in a way that will affect the functionality or operation of the website or service or any other websites or the internet;</li>
                                <li>harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;</li>
                                <li>use manual or automated software to download data from the website or services, break, copy, recreate, republish, or reverse engineer the website or services;</li>
                                <li>use or access the website or services to build a competing or similar website; (i) use or access the website or services for any obscene or immoral purpose; or</li>
                                <li>interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet.</li>
                                <li>infringe on Sydetasker’s or any third party's copyright, patent, trademark, trade secret or other proprietary rights or intellectual property rights, rights of publicity, confidentiality or privacy;</li>
                            </ol>
                        </Paragraph>
                        <Paragraph>
                            You agree to grant Sydetasker a worldwide, perpetual, royalty-free license to use, reproduce, edit, distribute, translate, public, perform, or create derivative works from any material posted or provided to the website, application, or services for any purpose including commercial and advertising purposes in any medium. By granting us this license or right, we are under no obligation to: (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.
                        </Paragraph>
                        <Paragraph>
                            You agree you will maintain control of your Sydetasker account at all times. Including not permitting others to use your account or transferring or selling your account or any of its content to another person.
                        </Paragraph>
                        <Paragraph>
                            You agree that any information you supply or publish to Sydetasker will be up to date and kept up to date and is a truthful representation of the information requested.
                        </Paragraph>
                        <Paragraph>
                            You agree that Sydetasker may from time to time engage location-based or map-based functionality. The Sydetasker Platform may display the location of Posters and Taskers to persons browsing the Sydetasker Platform. A Poster should never disclose personal details such as the Poster's full name, street number, phone number or email address in a Post or in any other public communication on the Sydetasker Platform.
                        </Paragraph>
                        <Paragraph>
                            If You are a Tasker, You have the right to provide Services under an Executory Job Contract and to work in the jurisdiction where the Services are performed. You must comply with tax and regulatory obligations in relation to any payment received under an Executory Job Contract.
                        </Paragraph>
                        <Paragraph>
                            You must not, when supplying Services, charge a Poster any fees on top of the negotiated Executory Job Contract price.
                        </Paragraph>
                        <Paragraph>
                            You must not request payments outside of the Sydetasker Platform from the Poster for the Services except to the extent permitted by clause 3.11 and only if the Sydetasker Platform does not facilitate the reimbursement via the Payment Account of costs considered in clause 3.11.
                        </Paragraph>
                        <Paragraph>
                            If a Tasker agrees to pay some costs of completing the Services, such as equipment or supplies to complete the Services, the Tasker is solely responsible for obtaining any reimbursement from the Poster.
                            <br/>
                            <br/>
                            Sydetasker strongly advises Taskers not to agree to incur costs in advance of receiving the payment for these costs, unless the Tasker is confident the Poster will reimburse the costs promptly.
                        </Paragraph>
                        <Paragraph>
                            Taskers may subcontract part of the performance of Services to a third party, so long as that third-party is also a registered Sydetasker User. The Tasker who originally created the Executory Job Contract with the Poster is ultimately responsible for any and all duties and Services outlined in the Executory Job Contract. Contracts between other Taskers and third-parties are between themselves. Sydetasker may refuse to recognize any subcontractor contract in its sole discretion without notice.
                        </Paragraph>
                        <Paragraph>
                            If Sydetasker determines at its sole discretion that any User, Poster, or Tasker has breached any obligation under this section or has breached one or more Job Contracts, it reserves the right to remove any content, Posted Job, Offer, or any other material submitted to the Sydetasker Website, App, or Services or cancel or suspend the User, Poster, or Tasker’s account and/or any Executory Job Contracts.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       4. FEES
                    </Text>
                    <ParagraphWrapper number={"4"}>
                        <Paragraph>
                            Upon creation of the Executory Job Contract, Sydetasker has rendered Sydetasker Services and the Service Fee is due and payable. The Service Fee will automatically be deducted from the Agreed Price held in the Payment Account.
                        </Paragraph>
                        <Paragraph>
                            If the Posted Job requires a Tasker to incur costs in completing the Services, the cost incurred will not be included in any calculation of Fees.
                        </Paragraph>
                        <Paragraph>
                            Fees do not include any fees that may be due to Third Party Service providers. All Third Party Service providers are paid pursuant to a User's separate agreement with that Third Party Service provider.
                        </Paragraph>
                        <Paragraph>
                            All Fees and charges payable to Sydetasker are non-cancellable and non-refundable.
                        </Paragraph>
                        <Paragraph>
                            Sydetasker may restrict a User's account until all Fees have been paid.
                        </Paragraph>

                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       5. CANCELLATIONS AND REFUNDS
                    </Text>
                    <ParagraphWrapper number={"5"}>
                        <Paragraph>
                            If the Executory Job Contract is cancelled by a Tasker for any reason after the Poster’s acceptance of the Offer, or if Sydetasker is reasonably satisfied that the Agreed Price should be returned to the Poster, then the Agreed Price will be refunded to the Poster.
                        </Paragraph>
                        <Paragraph>
                            If the Executory Job Contract is cancelled by a Poster for any reason after the Poster’s acceptance of the Offer, or if Sydetasker is reasonably satisfied that the Agreed Price should be returned to the Poster, then the Agreed Price will be refunded to the Poster and a Cancellation Fee will be due to Sydetasker by the Poster.
                        </Paragraph>
                        <Paragraph>
                            Sydetasker may decide in its absolute discretion to refund the Agreed Price back onto the Poster's credit card or bank account or waive the Cancellation Admin Fee. The Poster may be responsible for any cancellation fee associated with a third-party service (e.g. Stripe/PayPal).
                        </Paragraph>
                        <Paragraph>
                            Any amount returned by Sydetasker to a Poster on behalf of a Tasker under clause 5.1 or 5.2 will be a debt owed by the Tasker to Sydetasker.
                        </Paragraph>
                        <Paragraph>
                            Any outstanding Cancellation Admin Fee owed by a User under clause 5.1 or 5.2 will be a debt owed by that User to Sydetasker.
                        </Paragraph>
                        <Paragraph>
                            Cancellation of an Executory Job Contract will be attributable to the Tasker where:
                            <ol style={{ listStyleType: "lower-alpha" }}>
                                <li>the Tasker does not show up for the task; or</li>
                                <li>the Tasker does not have the skills/tools/materials needed to complete the task; or</li>
                                <li>the Tasker is asking for more than the assigned price (attributable to clause 5.1 or 5.2)</li>
                                <li>following reasonable but unsuccessful attempts by a Poster to contact a Tasker to perform the Executory Job Contract, the Executory Job Contract is cancelled by the Poster; or</li>
                                <li>the Tasker is no longer available to complete the Executory Job Contract on the scheduled date</li>
                                <li>an Executory Job Contract is cancelled in accordance with clause 3.13 as a result of the Tasker’s actions or breach.</li>
                            </ol>
                        <Paragraph>
                            Cancellation Admin fees are charged to the Tasker in relation to clause 5.6; the Tasker will be charged 5% of the original task amount, up to $20. Sydetasker reserves the right to find another Tasker to complete the task.
                        </Paragraph>
                        </Paragraph>
                        <Paragraph>
                            A Cancellation of an Executory Job Contract will be attributable to a Poster where:
                            <ol style={{ listStyleType: "lower-alpha" }}>
                                <li>the Poster cancels the Executory Job Contract (other than in accordance with clause 5.6); or</li>
                                <li>following reasonable but unsuccessful attempts by a Tasker to contact a Poster where it is obstructing the Tasker’s ability to complete the task; or</li>
                                <li>an Executory Job Contract is cancelled in accordance with clause 3.13 as a result of the Poster’s actions or breach.</li>
                            </ol>
                        </Paragraph>
                        <Paragraph>
                            Cancellation Admin fees for the Poster attributable to clause 5.7 are as follows:
                            <ol style={{ listStyleType: "lower-alpha" }}>
                                <li>If the Poster decides to cancel an assigned task within 24 hours of the date of the task, they will be charged 5% of the task amount, up to $25. The initial 5% booking fee is also non-refundable to the Poster in this situation.</li>
                                <li>If the Poster decides to cancel an assigned task within 24-48 hours of the task date, they will be charged 4% of the task amount, up to $25. The initial 5% booking fee is also non-refundable to the Poster in this situation.</li>
                                <li>If the Poster decides to cancel an assigned task 48 hours or greater before the task date, they will be charged 3% of the task amount, up to $25. The initial 5% booking fee is also non-refundable to the Poster in this situation.</li>
                            </ol>
                        </Paragraph>
                        <Paragraph>
                            In a case where the Users clicks “Request Cancellation” due to their selection of cancellation reasoning, Sydetasker has absolute discretion to make a decision based upon provided evidence from the Tasker and Poster, conditional upon the mediation and dispute process in clause 23.
                        </Paragraph>
                        <Paragraph>
                            If the parties agree to any additional cancellation fee payable under the Executory Job Contract, it is the responsibility of the party negatively effected to claim any amount owed directly from the other.
                        </Paragraph>
                        <Paragraph>
                            Sydetasker may take up to 14 days to return the Agreed Price (less the Cancellation Admin Fee, if applicable) to the Poster.
                        </Paragraph>
                        <Paragraph>
                            If, for any reason, the Poster Funds cannot be transferred or otherwise made to the Tasker or returned to the Poster (as the case may be) or no claim is otherwise made for the Agreed Price Funds, the Poster Funds will remain in the Payment Account until paid or otherwise for up to three months from the date the Poster initially paid the Agreed Price into the Payment Account.
                        </Paragraph>
                        <Paragraph>
                            If the Executory Job Contract is cancelled and a User who is party to the Executory Job Contract can show that work under a Executory Job Contract was commenced, then the amount of the Agreed Price to be returned to the Poster will be conditional upon the mediation and dispute process in clause 23. However, the Cancellation Admin Fee will always be due in accordance with clause 5.1.
                        </Paragraph>

                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       6. PAYMENT FACILITY
                    </Text>
                    <ParagraphWrapper number={"6"}>
                        <Paragraph>
                            Sydetasker uses a Third-Party payment service to operate the Payment Account.
                        </Paragraph>
                        <Paragraph>
                            Payment processing services for Taskers on Sydetasker are provided by Stripe and are subject to the {" "}<InlineLink to={"https://stripe.com/connect-account/legal"}>Stripe Connected Account Agreement</InlineLink>,{" "}
                            which includes the{" "}<InlineLink to={"https://stripe.com/legal"}>Stripe Terms of Service</InlineLink> {" "}(collectively, the “Stripe Services Agreement”). By agreeing to these terms or continuing to operate as a Tasker on Sydetasker, you agree to be bound by the Stripe Services Agreement, as the same may be modified by Stripe from time to time. As a condition of Sydetasker enabling payment processing services through Stripe, you agree to provide Sydetasker accurate and complete information about you and your business, and you authorize Sydetasker to share it and transaction information related to your use of the payment processing services provided by Stripe.
                        </Paragraph>
                        <Paragraph>
                            If Sydetasker changes its Payment Provider You may be asked to agree to any further additional termswith those providers. If you do not agree to them, you will be given alternative means of payment.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       7. THIRD PARTY SERVICES
                    </Text>
                    <ParagraphWrapper number={"7"}>
                        <Paragraph>
                            Sydetasker may from time to time include Third Party Services on the Sydetasker Platform. These Third Party Services are not provided by Sydetasker. Sydetasker is not responsible or liable for the accuracy of the content, any harm or damages, or the efficacy of other third-party websites that are not affiliated with us.
                        </Paragraph>
                        <Paragraph>
                            Third Party Services are offered to Users pursuant to the third party's terms and conditions. Sydetasker provides access to third-party tools “as is” and “as available” without any warranties, express or implied, or any endorsement. Any use by you of optional tools is entirely at your own risk and discretion.
                        </Paragraph>
                        <Paragraph>
                            If a User engages with any Third Party Service provider, the agreement will be directly between the User and that Third Party Service provider.
                        </Paragraph>
                        <Paragraph>
                            Sydetasker may provide links to other third-party websites, but it is not responsible for monitoring their content for accuracy or any other purpose, nor does Sydetasker warrant any materials, products, or services from third-party sites.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       8. VERIFICATION
                    </Text>
                    <ParagraphWrapper number={"8"}>
                        <Paragraph>
                            Sydetasker may use Identity Verification Services and all new Users may be subject to a background check before any Services or Posts may be completed through the site.
                        </Paragraph>
                        <Paragraph>
                            You agree that Sydetasker Identity Verification Services may not be fully accurate as all Sydetasker Services are dependant on User-supplied information and/or information or Verification Services provided by third parties.
                        </Paragraph>
                        <Paragraph>
                            You are solely responsible for identity verification and Sydetasker accepts no responsibility for any use that is made of a Sydetasker Identity Verification Service.
                        </Paragraph>
                        <Paragraph>
                            Sydetasker Identity Verification Services may be modified at any time.
                        </Paragraph>
                        <Paragraph>
                            The Sydetasker Platform may also include a User-initiated feedback system to help evaluate Users.
                        </Paragraph>
                        <Paragraph>
                            Sydetasker may make Badges available to Taskers. The Badge may be requested by the Tasker via the Sydetasker Platform, and arranged on behalf of the Tasker and issued by Sydetasker, for a fee.
                        </Paragraph>
                        <Paragraph>
                            It is the Tasker’s responsibility to ensure that information or documentation provided in obtaining a Badge is true and accurate and must inform Sydetasker immediately if a Badge is no longer valid.
                        </Paragraph>
                        <Paragraph>
                            Sydetasker may, at its discretion, issue Badges to Taskers for a fee.
                        </Paragraph>
                        <Paragraph>
                            The issue of a Badge to a Tasker remains in the control of Sydetasker and the display and use of a Badge is licensed to the Tasker for use on the Sydetasker Platform only. Any verification obtained as a result of the issue of a Badge may not be used for any other purpose outside of the Sydetasker Platform.
                        </Paragraph>
                        <Paragraph>
                            Sydetasker may, in its sole discretion, refuse to issue, or remove without notice, a Badge if a User is in breach of any of the terms of this Agreement; or the Badge has been issued incorrectly, obtained falsely, has expired, is no longer valid, or for any other reason.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       9. INSURANCE
                    </Text>
                    <ParagraphWrapper number={"9"}>
                        <Paragraph>
                            Sydetasker does NOT provide insurance to Users of the Sydetasker Website, App, or Services.
                        </Paragraph>
                        <Paragraph>
                            Users must make their own inquiries about whether any further insurance is required. Taskers remain responsible for ensuring that they have, and maintain, sufficient insurance to cover the Services provided to other Users on the Sydetasker Platform.
                        </Paragraph>
                        <Paragraph>
                            Sydetasker strongly encourages Users to seek advice and guidance from a licensed insurance agent regarding their liability insurance needs.
                        </Paragraph>
                        <Paragraph>
                            Sydetasker may also take out other insurance itself and that insurance may at Sydetasker’s option extend some types of cover to Users. Sydetasker reserves the right to change the terms of its insurance policies with the third party insurance providers at any time. A summary of the policies are available on the Sydetasker website and the policy details can be requested via Sydetasker. Users are responsible for familiarizing themselves with these details.
                        </Paragraph>
                        <Paragraph>
                            You acknowledge and agree that in the event that a claim is made relating to any services performed and/or goods provided by a Tasker, and the insurance taken out by Sydetasker (if any) responds to that claim then this clause applies. If a claim is made against a Tasker, Sydetasker may (provided that the Tasker consents) elect to make a claim under any applicable policy. However, Sydetasker is under no duty to make a claim and is not responsible for User’s insurance claims. If the claim is successful, Sydetasker reserves its right to recover any excess or deductible payable in respect of the claim from the Tasker. Where Sydetasker makes a claim and the insurer assesses that the Tasker is responsible, Sydetasker is entitled to rely on that assessment. If You do not pay any excess due under this clause, Sydetasker may also elect to set this amount off some or all of the excess paid by it against future moneys it may owe to You.
                        </Paragraph>
                        <Paragraph>
                            You acknowledge and agree that in the event that a claim is made relating to any services performed and/or goods provided by a Tasker, and the insurance taken out by Sydetasker (if any) does not respond to the claim or the claim is below the excess payable to the insurer, then this clause applies. Sydetasker may elect to reject or pay an amount to settle a claim not covered by Sydetasker’s own insurance policies. To the extent that the Tasker was or would be liable for the amount of the claim, if Sydetasker elects to pay an amount to settle the claim the amount paid by Sydetasker may be recovered by Sydetasker from the Tasker. Sydetasker may also elect to set this amount off against future moneys it may owe to the Tasker.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       10. LIMITATION OF LIABILITY
                    </Text>
                    <ParagraphWrapper number={"10"}>
                        <Paragraph>
                            You understand that in no case shall Sydetasker or any of its officers, directors, employees, affiliates, agents, interns, suppliers, or licensors be liable for any injury, loss, claim, or any indirect, incidental, special, consequential or exemplary damages (even if such parties were advised of the possibility of such damages) arising out of or related to your use of the website, services, or products, regardless of whether such damages are based on contract, tort, negligence, strict liability or otherwise. The parties acknowledge that the terms of this paragraph reflect the allocation of risk set forth in this Terms and Conditions Agreement and that the parties would not enter into this agreement without these limitations of liability. In jurisdictions that do not allow the exclusion or limitation of liability for consequential or incidental damages, our liability shall be limited to the maximum extent permitted by law.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       11. PRIVACY
                    </Text>
                    <ParagraphWrapper number={"11"}>
                        <Paragraph>
                            Any personal information volunteered on the Sydetasker Website, App, or Services is governed by our <InlineLink to={"/privacy-policy"}>Privacy Policy</InlineLink>
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       12. MODIFICATIONS TO THE AGREEMENT
                    </Text>
                    <ParagraphWrapper number={"12"}>
                        <Paragraph>
                            Sydetasker may modify these Terms or the Policies at any time in its sole discretion without notifying the user.
                        </Paragraph>
                        <Paragraph>
                            When changes are made, Sydetasker will make a new copy of the Terms and Conditions available on the Sydetasker website and will list the “Last Updated” date at the top of the Terms and Conditions.
                        </Paragraph>
                        <Paragraph>
                            Any changes to the Terms of Use will be effective immediately for all users of Sydetasker’s Website, App, and Services. Sydetasker may require you to provide consent to the updated Terms and Conditions before further use of Website, App, or Services is permitted.
                        </Paragraph>
                        <Paragraph>
                            If You do not agree with any changes to this Agreement (or any of our Policies), You must either terminate your account or You must notify Sydetasker who will terminate Your Sydetasker account, and stop using the Sydetasker Website, App, or Services.
                        </Paragraph>

                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       13. NO AGENCY
                    </Text>
                    <ParagraphWrapper number={"13"}>
                        <Paragraph>
                            No agency, partnership, joint venture, employee-employer or other similar relationship is created by this Agreement. In particular You have no authority to bind Sydetasker, its related entities or affiliates in any way whatsoever. Sydetasker confirms that all Third Party Services that may be promoted on the Sydetasker Platform are provided solely by such Third Party Service providers. To the extent permitted by law, Sydetasker specifically disclaims all liability for any loss or damage incurred by You in any manner due to the performance or non-performance of such Third Party Service.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       14. NOTICES
                    </Text>
                    <ParagraphWrapper number={"14"}>
                        <Paragraph>
                            Except as stated otherwise, any notices must be given by registered ordinary post or by email, either to Sydetasker’s contact address as displayed on the Sydetasker Platform, or to Sydetasker Users' contact address as provided at registration. Any notice shall be deemed given:
                            <ol style={{ listStyleType: "lower-alpha" }}>
                                <li>If sent by email, 24 hours after email is sent, unless the User is notified that the email address is invalid or the email is undeliverable, and</li>
                                <li>If sent by pre-paid post, three Business Days after the date of posting, or on the seventh Business Day after the date of posting if sent to or posted from outside the jurisdiction in which You have Your Sydetasker Platform account.</li>
                            </ol>
                        </Paragraph>
                        <Paragraph>
                            Notices related to performance of any Third Party Service must be delivered to such third party as set out in the Third Party Service provider's terms and conditions.
                        </Paragraph>
                        <Paragraph>
                            You agree that you are responsible for providing Sydetasker with a valid and your most current email address so that Sydetasker can provide you with notice. If your email address is not valid or Sydetasker emailed you at an invalid or old email address provided by you, Sydetasker’s dispatch of that email containing the notice constitutes effective notice.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       15. ELECTRONIC COMMUNICATION
                    </Text>
                    <ParagraphWrapper number={"15"}>
                        <Paragraph>
                            You agree that emails and online communication for contractual means satisfy the legal requirement that those agreements be in writing.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       16. ASSIGNMENT
                    </Text>
                    <ParagraphWrapper number={"16"}>
                        <Paragraph>
                            The rights and obligations that you agreed to in this Terms of Use are yours and yours alone. You cannot assign, delegate, transfer, or shift these rights and obligations to another by any other means. Any attempt to do so will be considered null and void.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                        17. INDEMNIFICATION
                    </Text>
                    <ParagraphWrapper number={"17"}>
                        <Paragraph>
                            You agree to indemnify, defend and hold harmless Sydetasker, including all agents, interns, employees, representatives, suppliers, service providers, licensors, affiliates, officers and directors of Sydetasker from and against any and all claims, liabilities, damages, losses, costs, expenses, fees, including reasonable attorneys' fees made by any third-party arising out of your breach of these Terms and Conditions and referenced policies, or by your violation or any law, or by your violation of the rights of any third-party.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       18. ENTIRE AGREEMENT
                    </Text>
                    <ParagraphWrapper number={"18"}>
                        <Paragraph>
                            These Terms of Use and referenced policies are the final, complete and exclusive agreement between you and Sydetasker and govern your use of the website, services, and products, and supersede all prior discussions, proposals, or understandings between you and Sydetasker.
                        </Paragraph>
                        <Paragraph>
                            Any ambiguities in the interpretation of these Terms of Use shall not be construed against the drafting party.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       19. SEVERABILITY
                    </Text>
                    <ParagraphWrapper number={"19"}>
                        <Paragraph>
                            An issue in one portion of this Terms of Use does not invalidate the other provisions.
                        </Paragraph>
                        <Paragraph>
                            If any provision or portion of this Terms of Use Agreement is, for any reason, held to be invalid or unenforceable, the other provisions of the Terms of Use will remain enforceable, and the invalid or unenforceable provision will be deemed severed from this Terms of Use agreement so that it is valid and enforceable to the maximum extent permitted by applicable law.
                            These Terms of Use and referenced policies are the final, complete and exclusive agreement between you and Sydetasker and govern your use of the website, services, and products, and supersede all prior discussions, proposals, or understandings between you and Sydetasker.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       20. WAIVER
                    </Text>
                    <ParagraphWrapper number={"20"}>
                        <Paragraph>
                            Any waiver or failure to enforce any provision of the Terms of Use on one occasion will not be deemed a waiver of any other provision or of such provision on any other occasion.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       21. GOVERNING LAW & BREACH
                    </Text>
                    <ParagraphWrapper number={"21"}>
                        <Paragraph>
                            This Terms of Use agreement and referenced policies therein are governed and construed in accordance with the laws of the United States and the State of Wisconsin without regard to the conflicts of laws or principles thereof.  Any action or suit related to this Agreement shall be brought in the Wisconsin state courts, or federal courts sitting in the Eastern District of Wisconsin, respectively.  The Taskers and Posters agree to exclusive forum, venue and personal jurisdiction in said Wisconsin state and federal courts.
                        </Paragraph>
                        <Paragraph>
                            The Taskers and Posters agree the Company may suffer irreparable harm if this Agreement is breached.  Consequently, the Taskers and Posters hereby agree that in such a breach event, the Company shall be entitled, in addition to such monetary relief,  fees and expenses (including, attorneys’ fees, expert fees, and other professional fees and costs) as may be recoverable by law, but also be entitled to equitable relief including temporary, preliminary and/or permanent injunctive relief as may be necessary to restrain any threatened, actual continuing, or further breach by the Taskers or Posters without showing or proving any actual damages sustained by Company, and without any requirement the Company posting a bond or other surety.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       22. MEDIATION AND DISPUTE RESOLUTION
                    </Text>
                    <ParagraphWrapper number={"22"}>
                        <Paragraph>
                            Sydetasker encourages You to try and resolve disputes (including claims for returns or refunds) with other Users directly. Accordingly, You acknowledge and agree that Sydetasker may, in its absolute discretion, provide Your information as it decides is suitable to other parties involved in the dispute.
                        </Paragraph>
                        <Paragraph>
                            If a dispute arises with another User, You must cooperate with the other User and make a genuine attempt to resolve the dispute.
                        </Paragraph>
                        <Paragraph>
                            Sydetasker may elect to assist Users resolve disputes. Any User may refer a dispute to Sydetasker. You must cooperate with any investigation undertaken by Sydetasker. Sydetasker reserves the right to make a final determination (acting reasonably) based on the information supplied by the Users and direct the Payment Provider to make payment accordingly. You may raise your dispute with the other User or Sydetasker’s determination in an applicable court or tribunal.
                        </Paragraph>
                        <Paragraph>
                            Sydetasker has the right to hold any Agreed Price that is the subject of a dispute in the Payment Account, until the dispute has been resolved.
                        </Paragraph>
                        <Paragraph>
                            If You or a Poster disputes any payment made with a credit card and initiates a credit card chargeback procedure, then You or the Poster agree it is responsible for all chargeback fees incurred by the Company.  Any disputed funds for any Agreed Price will be held by the Company until the chargeback dispute is settled with the corresponding credit card company.
                        </Paragraph>
                        <Paragraph>
                            Sydetasker may provide access to a Third Party Dispute Service. If such a service is provided, either party may request the other party to submit to the Third Party Dispute Service if the parties have failed to resolve the dispute directly. Terms and conditions for the Third Party Dispute Service will be available on request. The Third Party Dispute Service is a Third Party Service and Users are responsible for paying any costs associated with the Third Party Dispute Service in accordance with the Third Party Dispute Service terms and conditions.
                        </Paragraph>
                        <Paragraph>
                            Disputes with any Third Party Service provider must proceed pursuant to any dispute resolution process set out in the terms of service of the Third Party Service provider.
                        </Paragraph>
                        <Paragraph>
                            If You have a complaint about the Sydetasker Service please contact us at <InlineLink to={"tel:+1 (262) 470-3152"}>+1 (262) 470-3152</InlineLink> or <InlineLink to={"mailto:contact@sydetasker.com"}>contact@sydetasker.com</InlineLink>.
                        </Paragraph>
                        <Paragraph>
                            If Sydetasker provides information about other Users to You for the purposes of resolving disputes under this clause, You acknowledge and agree that such information will be used only for the purpose of resolving the dispute (and no other purpose) and that you will be responsible and liable to Sydetasker for any costs, losses or liabilities incurred by Sydetasker in relation to any claims relating to any other use of information not permitted by this Agreement.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       23. TERMINATION
                    </Text>
                    <ParagraphWrapper number={"23"}>
                        <Paragraph>
                            Any User may terminate their account at anytime for any reason.
                        </Paragraph>
                        <Paragraph>
                            Sydetasker may terminate any User’s account at anytime for any reason in its absolute discretion.
                        </Paragraph>
                        <Paragraph>
                            Termination of this Agreement does not affect any Executory Job Contract that has been formed between Sydetasker Users.
                        </Paragraph>
                        <Paragraph>
                            Upon Termination, Sydetasker will retain all User information until all Jobs and Payments have been completed by that User.
                        </Paragraph>
                        <Paragraph>
                            Third Party Services are conditional upon, and governed by, Third Party Service provider terms and conditions.
                        </Paragraph>
                        <Paragraph>
                            Clauses 4 (Fees), 11 (Limitation of Liability) and 23 (Mediation and Dispute Resolution) and any other terms which by their nature should continue to apply, will survive any termination or expiration of this Agreement.
                        </Paragraph>
                        <Paragraph>
                            If Your account or this Agreement are terminated for any reason then You may not without Sydetasker’s consent (in its absolute discretion) create any further accounts with Sydetasker and we may terminate any other accounts You operate.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "64px" }}/>
                    <Text variant={"h2"} bold >
                       APPENDIX A
                    </Text>
                    <Text variant={"h6"} medium className={classes.subHeading}>
                        MODEL EXECUTORY JOB CONTRACT
                    </Text>
                    <Text variant={"body2"} regular>
                        An Executory Job Contract is created in accordance with the Sydetasker Agreement. Unless otherwise agreed, the Poster and the Tasker enter into an Executory Job Contract on the following terms:
                    </Text>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                        1. COMMENCEMENT DATE AND TERM
                    </Text>
                    <ParagraphWrapper number={"1"}>
                        <Paragraph>
                            The Executory Job Contract is created when the Poster accepts the Tasker’s Offer on a Posted Job to provide Services. When using Search Assist, the Executory Job Contract is created when the Tasker makes an Instant Claim.
                        </Paragraph>
                        <Paragraph>
                            The Contract will continue until the duties and obligations of each party are fully completed or until terminated in accordance with clause 7.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                        2. SERVICES
                    </Text>
                    <ParagraphWrapper number={"2"}>
                        <Paragraph>
                            The Tasker will perform Services in a proper and workmanlike manner.
                        </Paragraph>
                        <Paragraph>
                            The Tasker must perform the Services at the time and location agreed.
                        </Paragraph>
                        <Paragraph>
                            The parties must perform their obligations in accordance with any other terms or conditions agreed by the parties during or subsequent to the creation of the Executory Job Contract.
                        </Paragraph>
                        <Paragraph>
                            The parties acknowledge that the Executory Job Contract is one of personal service where the Poster selected the Tasker to perform the Services. Therefore, the Tasker must not subcontract any part of the Services to any third party without the Poster's consent.
                        </Paragraph>
                        <Paragraph>
                            The Tasker remains responsible and liable at all times to the Poster for any acts or omissions of a subcontractor as if those acts or omissions had been made by the Tasker.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                        3. WARRANTIES
                    </Text>
                    <ParagraphWrapper number={"3"}>
                        <Paragraph>
                            Each party warrants that the information provided in the creation of the Executory Job Contract is true and accurate.
                        </Paragraph>
                        <Paragraph>
                            The Tasker warrants that they have (and any subcontractor has) the right to work and provide Services and hold all relevant licenses in the jurisdiction where the Services are performed.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                        4. PAYMENT OR CANCELLATION
                    </Text>
                    <ParagraphWrapper number={"4"}>
                        <Paragraph>
                            Upon the creation of the Executory Job Contract, the Poster must pay the Agreed Price into the Payment Account.
                        </Paragraph>
                        <Paragraph>
                            Upon the Services being completed, the Tasker will provide notice on the Sydetasker Platform.
                        </Paragraph>
                        <Paragraph>
                            The Poster will be prompted to confirm the Services are complete. If the Tasker has completed the Services in accordance with clause 2, the Poster must use the Sydetasker Platform to release the Tasker Funds from the Payment Account.
                        </Paragraph>
                        <Paragraph>
                            If the parties agree to cancel the Executory Job Contract, or the Poster is unable to contact the Tasker to perform the Executory Job Contract, the Tasker Funds will be dealt with in accordance with the User's Sydetasker Agreement (Sydetasker’s Terms and Conditions).
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                        5. LIMITATION OF LIABILITY
                    </Text>
                    <ParagraphWrapper number={"5"}>
                        <Paragraph>
                            Except for Non-Excludable Conditions, the parties exclude all Consequential Loss arising out of or in connection to the Services, and any claims by any third person, or the Executory Job Contract, even if the party causing the breach knew the loss was possible or the loss was otherwise foreseeable.
                        </Paragraph>
                        <Paragraph>
                            Subject to any insurance or agreement to the contrary, the liability of each party to the other except for a breach of any Non-Excludable Condition is capped at the Agreed Price.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                        6. DISPUTES
                    </Text>
                    <ParagraphWrapper number={"6"}>
                        <Paragraph>
                            If a dispute arises between the parties, the parties will attempt to resolve the dispute within 14 days by informal negotiation (by phone, email or otherwise).
                        </Paragraph>
                        <Paragraph>
                            If the parties are unable to resolve the dispute in accordance with clause 6.1, either party may refer the dispute to Sydetasker and act in accordance with clause 23 of the Sydetasker Terms and Conditions.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                        7. TERMINATION OF CONTRACT
                    </Text>
                    <ParagraphWrapper number={"7"}>
                        <Paragraph>
                            The Executory Job Contract will terminate when:
                            <ol style={{ listStyleType: "lower-alpha" }}>
                                <li>The Services are completed and the Agreed Price is released from the Payment Account; (b) a party is terminated or suspended from the Sydetasker Platform;</li>
                                <li>Otherwise agreed by the parties or the Third Party Dispute Service; or</li>
                                <li>Notified by Sydetasker of cancellation in accordance with Sydetasker’s Terms and Conditions.</li>
                            </ol>
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                        8. APPLICATION OF POLICIES
                    </Text>
                    <ParagraphWrapper number={"8"}>
                        <Paragraph>
                            The parties incorporate by reference the applicable Policies.
                        </Paragraph>
                    </ParagraphWrapper>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                        9. GOVERNING LAW
                    </Text>
                    <ParagraphWrapper number={"9"}>
                        <Paragraph>
                            The Executory Job Contract is governed by the laws of the jurisdiction where the Posted Job was posted on the Sydetasker Platform.
                        </Paragraph>
                    </ParagraphWrapper>
                </div>
            </Container>
            <div style={{ height: "1px", width: "100%", background: "rgb(220,220,220)" }}/>
        </>
    )
}

export default TermsOfService